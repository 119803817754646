class diPostFilter {
	constructor(params = {}) {
		const settings = Object.assign(
			{},
			{
				postContainer: ".elementor-posts-container",
				postClass: ".elementor-post",
				buttonContainer: ".di-post-filter",
				buttonClass: ".di-post-filter__option",
			},
			params
		);

		const container = document.querySelector(settings.postContainer);
		const posts = [...container.querySelectorAll(settings.postClass)];
		const buttonContainer = document.querySelector(settings.buttonContainer);
		const buttons = [...buttonContainer.querySelectorAll(settings.buttonClass)];

		this.settings = settings;
		this.container = container;
		this.posts = posts;
		this.buttonContainer = buttonContainer;
		this.buttons = buttons;

		// extract available filter term-ids from buttons
		this._filterTerms = buttons.reduce((a, b) => {
			if (b.dataset.termId) {
				a[b.dataset.termId] = false;
			}
			return a;
		}, {});
	}

	_getKeys() {
		return Object.keys(this._filterTerms);
	}

	_getActive() {
		return Object.entries(this._filterTerms)
			.filter((pair) => !!pair[1])
			.map((pair) => pair[0]);
	}

	_turnOn(key) {
		key = parseInt(key, 10);
		this._filterTerms[key] = true;
	}

	_turnOff(key) {
		key = parseInt(key, 10);
		this._filterTerms[key] && (this._filterTerms[key] = false);
		return this;
	}

	_toggle(key) {
		if (this._filterTerms[key]) {
			this._turnOff(key);
		} else {
			this._turnOn(key);
		}
	}

	togglePosts() {
		const activeTerms = this._getActive().map((key) => `term-${key}`);

		if (0 === activeTerms.length) {
			// show all posts if no filters are active
			this.posts.forEach((post) => post.classList.remove("hidden"));
		} else {
			// else compare each post against list of active filters
			this.posts.forEach((post) => {
				let shouldToggle = [...post.classList].some((name) =>
					activeTerms.includes(name)
				);
				console.log(shouldToggle);
				if (shouldToggle) {
					post.classList.remove("hidden");
				} else {
					post.classList.add("hidden");
				}
			});
		}
	}

	toggleButtonState() {
		const activeTerms = this._getActive().map(
			(key) => `[data-term-id="${key}"]`
		);
		const activeTermsString = activeTerms.join(",");

		if (0 === activeTerms.length) {
			// show all posts if no filters are active
			this.buttons.forEach((button) => button.classList.remove("is_active"));
		} else {
			// else compare each post against active terms selector string
			this.buttons.forEach((button) => {
				if (button.matches(activeTermsString)) {
					button.classList.add("is_active");
				} else {
					button.classList.remove("is_active");
				}
			});
		}
	}

	reset() {
		this._getKeys().forEach((key) => {
			this._turnOff(key);
		});
		return this;
	}

	update() {
		this.togglePosts();
		this.toggleButtonState();
		return this;
	}

	clickHandler(event) {
		if (!event.currentTarget.matches(this.settings.buttonClass)) return;
		const button = event.currentTarget;
		let action;

		if (button.dataset.termId) {
			this._toggle(button.dataset.termId);
			action = "toggle";
		} else if (button.hasAttribute("data-reset")) {
			this.reset();
			action = "reset";
		}
		this.update();
		console.log(action, this._filterTerms);
	}

	init() {
		// console.log(this.settings);
		this.buttons.forEach((el) =>
			el.addEventListener("click", this.clickHandler.bind(this))
		);
	}
}
try {
	let diObj = new diPostFilter();
	diObj.init();
} catch (error) {}

document.querySelectorAll(".toggle-posts-btn").forEach((item) => {
	item.addEventListener("click", togglePosts);
});
let activePost = document.querySelector(".active-post");
let oldPost = document.querySelector(".old-post");

function togglePosts() {
	oldPost.classList.toggle("hide");
	activePost.classList.toggle("hide");
	document.querySelectorAll(".toggle-posts-btn").forEach((item) => {
		item.classList.toggle("is_active");
	});
}

let futurEvent = document.querySelector(".future-event");

futurEvent.setAttribute("id", "first_future_event");
